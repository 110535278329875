import React, { useState } from 'react';
import styled from 'styled-components';
import { Checkbox, Select } from 'antd';
import { useQuery } from '@apollo/client';
import debounce from 'lodash.debounce';
import { DocumentNode } from 'graphql';
import { DefaultOptionType, SelectProps } from 'antd/lib/select';
import { DropdownArrow } from '../icons/icons';

type AsyncDropdownProps = {
  query: DocumentNode;
  onChange?: (value: string[]) => void;
  multiple?: boolean;
  value?: string | string[];
  placeholder?: string;
  disabled?: boolean;
  showSearch?: boolean;
  myRef?: React.Ref<any>;
  width?: string;
  styledSelection?: boolean;
  showCheckbox?: boolean;
  showClear?: boolean;
  maxTagCount?: number;
  maxTagWidth?: string;
  suggestedData?: string[];
  maxHeight?: number;
  fieldHeight?: string;
  disabledValues?: string[];
  dataTestId?: string;
};

export const AsyncDropdown = ({
  query,
  onChange: cbChange,
  multiple = false,
  value,
  placeholder,
  disabled,
  showSearch = true,
  myRef,
  width = '100%',
  styledSelection,
  showCheckbox,
  showClear,
  maxTagCount,
  maxTagWidth,
  suggestedData = [],
  maxHeight,
  fieldHeight,
  disabledValues,
  dataTestId,
}: AsyncDropdownProps) => {
  const [allData, setAllData] = useState<any[]>([]);
  const [currentPage, setPage] = useState(1);
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);

  const { data, loading, refetch } = useQuery(query, {
    variables: {
      page: currentPage,
      searchBy,
    },
    fetchPolicy: 'network-only',
  });

  const onPopupScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as any;

    if (
      data &&
      (Object.values(data)[0] as any).metadata.total <= allData.length
    ) {
      return;
    }

    if (
      Math.ceil(target.scrollHeight - target.scrollTop) === target.clientHeight
    ) {
      const newPage = currentPage + 1;

      setPage(newPage);
      refetch({
        searchBy,
        page: newPage,
      });
    }
  };

  const onSearch = debounce((value: string) => {
    setAllData([]);
    setSearchBy(value.trim());
    setPage(1);
  }, 500);

  const refilterAllData = React.useCallback(
    (fetched: any, existing?: string | string[]) => {
      const fetchedData = (Object.values(fetched)[0] as any).items as string[];
      const existingValues = Array.isArray(existing) ? [...existing] : [];

      const filteredData = [...existingValues, ...fetchedData].filter(
        (item) => !suggestedData.includes(item)
      );

      setAllData(Array.from(new Set([...allData, ...filteredData])));
    },
    [allData, suggestedData]
  );

  const refreshItems = async (changedValue?: string | string[]) => {
    const { data: refetchedData } = await refetch({
      searchBy: '',
      page: 1,
    });

    refilterAllData(refetchedData, changedValue || value);
  };

  const onChange = async (changedValue: string[]) => {
    if (cbChange) {
      cbChange(changedValue);
    }

    await refreshItems(changedValue);
  };

  const handleBlur = async () => {
    await refreshItems();
  };

  const filterSort = React.useCallback(
    (a: DefaultOptionType, b: DefaultOptionType) => {
      if (
        value?.includes(String(a.value)) === value?.includes(String(b.value))
      ) {
        return suggestedData.length === 0
          ? 0
          : suggestedData.includes(String(a.value))
          ? -1
          : 1;
      }

      return value?.includes(String(a.value)) ? -1 : 1;
    },
    [suggestedData, value]
  );

  React.useEffect(
    () => {
      if (!data || !Object.keys(data).length) {
        return;
      }

      refilterAllData(data, value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <StyledSelect
      data-testid={dataTestId}
      $maxTagWidth={maxTagWidth}
      $fieldHeight={fieldHeight}
      open={open}
      onDropdownVisibleChange={setOpen}
      onFocus={() => setOpen(true)}
      onBlur={handleBlur}
      disabled={disabled}
      ref={myRef}
      optionLabelProp="label"
      showSearch={showSearch}
      dropdownClassName={`${showCheckbox ? 'no-checks styled-dropdown' : ''}`}
      className={`async-dropdown ${styledSelection ? 'detailed-tags' : ''}${
        maxTagCount && value && value.length - maxTagCount >= 10
          ? ' more-than-10'
          : ' less-than-10'
      }`}
      dropdownRender={(menu) => (
        <div onScrollCapture={onPopupScroll}>{menu}</div>
      )}
      loading={loading}
      onSearch={showSearch ? onSearch : undefined}
      mode={multiple ? 'multiple' : undefined}
      value={value as string[]}
      onChange={onChange}
      placeholder={placeholder}
      style={{ width }}
      filterSort={filterSort}
      autoClearSearchValue
      allowClear={showClear}
      showArrow
      maxTagCount={maxTagCount}
      getPopupContainer={(trigger) => trigger.parentNode}
      dropdownStyle={{ maxHeight }}
      suffixIcon={<DropdownArrow $open={open} />}
    >
      <>
        {suggestedData.map((option: string) => (
          <Select.Option
            key={`genre-${option}`}
            label={option}
            value={option}
            disabled={disabledValues?.includes(option)}
            style={{
              display: (value as string[])?.includes(option) ? 'none' : 'block',
            }}
          >
            {showCheckbox && <Checkbox checked={value?.includes(option)} />}
            <span style={{ marginLeft: showCheckbox ? '10px' : '0' }}>
              {option}
              <span
                className="tag secondary"
                style={{ marginLeft: '20px', verticalAlign: 'top' }}
              >
                Suggested
              </span>
            </span>
          </Select.Option>
        ))}
        {allData.map((option: string) => (
          <Select.Option
            key={`genre-${option}`}
            label={option}
            value={option}
            disabled={disabledValues?.includes(option)}
            style={{
              display: (value as string[])?.includes(option) ? 'none' : 'block',
            }}
          >
            {showCheckbox && <Checkbox checked={value?.includes(option)} />}
            <span style={{ marginLeft: showCheckbox ? '10px' : '0' }}>
              {option}
            </span>
          </Select.Option>
        ))}
      </>
    </StyledSelect>
  );
};

export const StyledSelect = styled(Select).attrs(
  ({
    $maxTagWidth,
    $fieldHeight,
  }: {
    $maxTagWidth?: string;
    $fieldHeight?: string;
  }) => ({
    $maxTagWidth,
    $fieldHeight,
  })
)`
  & .ant-select-selection-overflow-item {
    max-width: ${(props) =>
      props.$maxTagWidth ? `${props.$maxTagWidth}` : 'none'};
  }

  ${(props) =>
    props.$fieldHeight &&
    `.ant-select-selector {
      height: ${props.$fieldHeight};
    }`}
` as <T>(
  props: SelectProps<T> & {
    $maxTagWidth?: string;
    $fieldHeight?: string;
    ref?: React.Ref<any>;
  }
) => React.ReactElement;
