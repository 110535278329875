import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './app';
import './index.less';
import './css/fontawesome-font-faces.css';
import './css/fontawesome.min.css';
import { AuthStateProvider } from './context/auth.context';
import { AppStateProvider } from './context/app.context';
import { Routes, Route } from 'react-router-dom';
import browserHistory from './utils/history';
import { CustomRouter as Router } from './custom-router';
import { KeycloakWrapper } from './components/auth/keycloak-wrapper/keycloak-wrapper.component';
import { ApolloWrapper } from './components/auth/apollo-wrapper/apollo-wrapper.component';
import { KeycloakRoleWrapper } from './components/auth/keycloak-role-wrapper/keycloack-role-wrapper.component';
import MatomoTagManager from './components/analytics/matomo-tag-manager.component';

const container = document.getElementById('root');

const root = createRoot(container!);

root.render(
  <AuthStateProvider>
    <AppStateProvider>
      <KeycloakWrapper>
        <KeycloakRoleWrapper>
          <ApolloWrapper>
            <MatomoTagManager
              matomoUrl={process.env.REACT_APP_MATOMO_URL}
              containerId={process.env.REACT_APP_MATOMO_CONTAINER_ID}
            />
            <Router history={browserHistory}>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </Router>
          </ApolloWrapper>
        </KeycloakRoleWrapper>
      </KeycloakWrapper>
    </AppStateProvider>
  </AuthStateProvider>
);
