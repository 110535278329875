import React, { useCallback, useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert } from 'antd';
import { DatePicker } from '../../../date-fns/DatePicker';
import {
  ModalCtaButton,
  ModalHeader,
  StyledModal,
} from '../../layout/modal/modal.styles';
import ArtistImage from '../../../assets/images/artist.svg';
import { ModalImage, StyledForm } from './form-modal.styles';
import { useCreationForm } from '../create-form-manager/hooks';
import { getDecorators } from './decorators';
import { birthdayDateValidator } from '../../../utils/validators';
import { DateFormats } from '../../../utils/date-formats';
import NameInput from '../name-input/name-input.component';
import {
  Artist,
  CountriesDocument,
  GenresDocument,
} from '../../../resolver.types';
import ArtistsWithSameNameExistsWarning from '../artist-with-same-name-exists-warning/artists-with-same-name-exists-warning.component';
import { AsyncDropdown } from '../../common/async-dropdown/async-dropdown.component';

export type FormModalProps = {
  onCancel: () => void;
};

const FormModal = ({ onCancel }: FormModalProps) => {
  const form = useCreationForm();

  const {
    birthday,
    country,
    genres: genresDecorator,
    name,
  } = getDecorators(form);

  const onArtistFound = useCallback(
    (artists: Artist[]) => {
      if (artists.length) {
        form.setStatus({
          state: 'warning',
          message: <ArtistsWithSameNameExistsWarning artists={artists} />,
        });
      } else {
        form.setStatus({});
      }
    },
    [form]
  );
  const [nameLoading, setNameLoading] = useState(false);

  const handleAlertClose = useCallback(() => {
    form.setStatus({});
  }, [form]);

  const { status } = form;

  const GenresDropdown = React.forwardRef((props, ref) => (
    <AsyncDropdown
      myRef={ref}
      query={GenresDocument}
      multiple
      placeholder="Provide genres"
      dataTestId="create-artist_genre"
      {...props}
    />
  ));

  const CountryDropdown = React.forwardRef((props, ref) => (
    <AsyncDropdown
      showClear
      query={CountriesDocument}
      myRef={ref}
      placeholder="Provide country"
      dataTestId="create-artist_country"
      {...props}
    />
  ));

  return (
    <StyledModal
      width="400px"
      onCancel={onCancel}
      footer={null}
      title={<ModalHeader>New Artist</ModalHeader>}
      visible
    >
      <StyledForm onSubmit={form.handleSubmit} className="compatible-row">
        <ModalImage src={ArtistImage} alt="" />
        {status.state && (
          <Alert
            style={{ marginBottom: '10px' }}
            className="form-alert"
            afterClose={handleAlertClose}
            closable
            message={form.status.message}
            type={status.state}
            showIcon
          />
        )}
        {/*
        // @ts-ignore */}
        <Form.Item className="form-item">
          {name(
            <NameInput
              autoFocus={true}
              onLoadingChange={setNameLoading}
              onArtistsFoundChange={onArtistFound}
              disabled={form.isSubmitting}
              placeholder="Please enter artist name"
            />
          )}
        </Form.Item>
        {/*
        // @ts-ignore */}
        <Form.Item className="form-item">
          {birthday(
            <DatePicker
              format={DateFormats.date}
              disabled={form.isSubmitting}
              disabledDate={birthdayDateValidator}
              placeholder="Birthday"
              showToday={false}
            />
          )}
        </Form.Item>
        {/*
        // @ts-ignore */}
        <Form.Item className={'form-item'}>
          {country(<CountryDropdown />)}
        </Form.Item>
        {/*
        // @ts-ignore */}
        <Form.Item className={'form-item'}>
          {genresDecorator(<GenresDropdown />)}
        </Form.Item>
        <Form.Item>
          <ModalCtaButton
            loading={form.isSubmitting}
            disabled={form.hasFieldErrors() || nameLoading}
            type="primary"
            htmlType="submit"
            icon={<PlusCircleOutlined />}
          >
            {!form.isSubmitting && <span>Create</span>}
            {form.isSubmitting && <span>Creating...</span>}
          </ModalCtaButton>
        </Form.Item>
      </StyledForm>
    </StyledModal>
  );
};

export default FormModal;
