import Keycloak from 'keycloak-js';

const getRedirectUrl = (redirectUrl?: string): string | undefined => {
  if (!redirectUrl) return redirectUrl;

  return `${redirectUrl}?post_login_redirect=${window.location.pathname}${window.location.search}`;
};

const realm = process.env.REACT_APP_KEYCLOAK_REALM ?? '';
const clientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? '';
const idpHint = process.env.REACT_APP_KEYCLOAK_IDP_HINT;
const url = process.env.REACT_APP_KEYCLOAK_ENDPOINT;
const redirectPage = process.env.REACT_APP_KEYCLOAK_REDIRECT_URI;
const keycloakClient = new Keycloak({
  realm,
  clientId,
  url,
});

const kcLogin = keycloakClient.login;

keycloakClient.login = (options: any) => {
  return kcLogin({ ...options, idpHint });
};

export { keycloakClient };

export const keycloakInitConfig = {
  onLoad: 'login-required',
  redirectUri: getRedirectUrl(redirectPage),
  pkceMethod: 'S256',
  enableLogging: true,
  checkLoginIframe: false,
};

export const logoutFromKeycloak = async () => {
  await keycloakClient.logout({ redirectUri: redirectPage });
};

export const isUserAuthorizedToPerformAction = (
  keycloak: typeof keycloakClient,
  roles: string[]
) => {
  return roles.some((r) => {
    const realm = keycloak.hasRealmRole(r);
    const resource = keycloak.hasResourceRole(r);
    return realm || resource;
  });
};
