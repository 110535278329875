import React, { useEffect } from 'react';

interface MatomoTagManagerProps {
  matomoUrl?: string;
  containerId?: string;
}

const MatomoTagManager: React.FC<MatomoTagManagerProps> = ({
  matomoUrl,
  containerId,
}) => {
  useEffect(() => {
    if (!matomoUrl || !containerId) return;

    window._mtm = window._mtm || [];
    window._mtm.push({
      'mtm.startTime': new Date().getTime(),
      event: 'mtm.js',
    });

    const containerUrl = `${matomoUrl}/container_${containerId}.js`;

    const existingScript = document.querySelector(
      `script[src="${containerUrl}"]`
    );
    if (existingScript) return;

    const script = document.createElement('script');
    script.async = true;
    script.src = containerUrl;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [matomoUrl, containerId]);

  return null;
};

export default MatomoTagManager;
